@import "../../../styles/global_variables.scss";

.dropdown {
	min-width: 100%;
	max-width: 100%;
	min-height: 40px;
	// white-space: normal;
	text-align: left !important;
	padding: 9px 25px 9px 12px;
	background: #fff !important;
	overflow: hidden;
	text-overflow: ellipsis;

	&:hover,
	&:focus,
	&.focus,
	&:active,
	&.active {
		background: #fff !important; }

	&:active,
	&.active {
		&:hover,
		&:focus,
		&.focus {
			background: #fff !important; }
	}
}

.dropdown-caret {
	position: absolute;
	top: 19px;
	right: 19px; }

.divider {
	margin: 8px 0 5px !important; }

.dropdown-menu-local {
	width: 100%;
	min-width: 90px;
	max-height: 250px;
	overflow: auto;

	> li > a {
		padding: 8px 20px 7px;
		white-space: normal;

		&.selected {
			color: #ffffff;
			background-color: #9da9ad; }
	}
}

.dropdown-button-a {
	width: 100%;
	text-align: left;
	padding: 8px 20px;
	clear: both;
	font-weight: normal;
	line-height: 1.42857;
	color: #333333;
	white-space: normal;
	border: none;
	background: none;
	outline: none;

	&:hover {
		text-decoration: none;
		color: #262626;
		background-color: #f5f5f5; }
}

.form-group-resposta {
	position: relative;
	float: left;
	width: 100%;

	&.has-unidade {
		width: 70%;
		padding-right: 10px; }
}

.form-group-unidades {
	position: relative;
	float: left;
	width: 30%; }

.col-resposta:after {
  content: "";
  display: table;
  clear: both; }

.item-pedido-topbar-right-d {
	float: left;
	width: 50%;
	padding-right: 10px; }

.checkbox-wrapper {
	padding: 1px 0 0 20px;
	/* margin-top: 6px !important; */ }

.checkbox {
	display:none; }

.checkbox + label {
	color: #555555;
	font-weight: normal;
	cursor: pointer; }

.checkbox + label span {
	display: inline-block;
	width: 20px;
	height: 20px;
	margin: -2px 10px 0 0;
	padding: 2px 0 0 2px;
	vertical-align: middle;
	border: 1px solid #ccc;
	border-radius: 3px;
	font-size: 14px;
	cursor: pointer; }

.checkbox + label span i {
	display: none; }

.checkbox:checked + label span {
	color: #fff;
	background: #337ab7;
	border: 1px solid #2f73ac; }

.checkbox:checked + label span i {
	display: block; }

.dropdown-menu-multiple {
	width: 100%;
	min-width: 90px;
	max-height: 185px;
	padding-top: 6px;
	overflow: auto; }

.checkbox-button {
	position: relative;
	width: 100%;
	padding: 8px 0 6px 48px;
	background: none;
	border: none;
	outline: none;
	text-align: left;

	> .checkbox-icon {
		position: absolute;
		top: 9px;
		left: 20px;
		width: 20px;
		height: 20px;
		margin: -2px 10px 0 0;
		padding: 2px 0 0 2px;
		vertical-align: middle;
		text-align: center;
		border: 1px solid #ccc;
		border-radius: 3px;
		font-size: 14px;
		cursor: pointer; }

	&.checked {
		> .checkbox-icon {
			padding-top: 0;
			color: #fff;
			background: #337ab7;
			border: 1px solid #2f73ac;

			> i {
				position: absolute;
				top: 3px;
				left: 2px; }
		}
	}
}

.opcional {
	color: #bcbcbc;
	font-weight: normal;
	font-style: italic; }

.obrigatorio-label {
	// font-weight: normal;
	// color: #9b9b9b;
	// font-size: 13px;
	color: #e43f3f; }

.salvar-outro-wrapper {
	position: relative;
	padding: 5px 20px 8px;

	> .input-outro {
		padding-right: 74px;
	
		&.unidade {
			float: left;
			width: 54%;
			margin-right: 1%;
			margin-bottom: 4px;
			padding-right: 0; }
	}

	> .input-outra-unidade {
		float: left;
		width: 45%;
		padding-right: 74px; }

	> button {
		position: absolute;
		top: 5px;
		right: 20px;
		height: 40px;
		padding-top: 6px;
		padding-left: 9px;
		padding-right: 10px; }

	> .tooltip-unidade {
		right: 5px;
		margin-top: -36px; }
}

.radio-button {
	position: relative;
	display: block;
	margin: 2px 0 0 0;
	padding: 6px 0 6px 31px;
	background: none;
	border: none;
	outline: none;
	text-align: left;

	> .radio {
		position: absolute;
		top: 5px;
		left: 3px;
		width: 21px;
		height: 21px;
		border: 1px solid #E3E3E3;
		border-radius: 30px;
		-webkit-border-radius: 30px;

		&:after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			width: 8px;
			height: 8px;
			margin: -4px 0 0 -4px;
			background-color: #1778d3;
			border-color: #1778d3;
			border-radius: 8px;
			-webkit-border-radius: 8px;
			opacity: 0;
			-webkit-transition: opacity 0.1s linear;
			-moz-transition: opacity 0.1s linear;
			-o-transition: opacity 0.1s linear;
			-ms-transition: opacity 0.1s linear;
			transition: opacity 0.1s linear; }
	}

	&.selected > .radio:after {
		opacity: 1; }
}

.radio-button-block {
	position: relative;
	display: block;
	width: 100%;
	max-width: 700px;
	margin: 8px 0 0 0;
	padding: 16px 16px 16px 38px;
	background: none;
	border: 1px solid #fff; // d8dde4
	outline: none;
	text-align: left;
	@include border-radius(4px);
	@include box-shadow(0px 0px 0px 1px rgba(216,221,228,.6));
	@include transition-two(border 0.1s linear, box-shadow 0.1s linear);

	> .radio {
		position: absolute;
		top: 18px;
		left: 9px;
		width: 21px;
		height: 21px;
		border: 1px solid #E3E3E3;
		@include border-radius(30px);

		&:after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			width: 8px;
			height: 8px;
			margin: -4px 0 0 -4px;
			background-color: #1778d3;
			border-color: #1778d3; // 448ec3
			opacity: 0;
			@include border-radius(8px);
			@include transition-one(opacity 0.1s linear); }
	}

	> .pagamento {
		position: relative;
		float: left;
		width: 100%;
		padding: 2px 0 8px 0; }

	> .preco {
		position: relative;
		float: left;
		width: 100%;
		text-align: left;

		> h3 {
			margin: 0;
			color: #1778d3; }
	}

	&:hover {
		@include box-shadow(0px 0px 0px 1px rgba(148,156,168,1)); }

	&.selected {
		border: 1px solid #1778d3;
		@include box-shadow(0px 0px 0px 1px rgba(68,142,195,1));

		> .radio:after {
			opacity: 1; }
	}
}

.outro-block-wrapper {
	position: relative;
	display: block;
	width: 100%;
	max-width: 700px;
	margin: 8px 0 0 0;
	padding: 16px 16px 32px 48px;
	border: 1px solid #1778d3;
	text-align: left;
	@include border-radius(4px);
	@include box-shadow(0px 0px 0px 1px rgba(68,142,195,1));

	> .radio {
		position: absolute;
		top: 18px;
		left: 15px;
		width: 21px;
		height: 21px;
		border: 1px solid #E3E3E3;
		@include border-radius(30px);

		&:after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			width: 8px;
			height: 8px;
			margin: -4px 0 0 -4px;
			background-color: #1778d3;
			border-color: #1778d3;
			@include border-radius(8px);
			@include transition-one(opacity 0.1s linear); }
	}

	> .pagamento {
		position: relative;
		float: left;
		width: 70%;
		padding: 3px 0 0 0; }

	> label {
		width: 100%;
		margin-top: 20px; }

	> p {
		color: #9e9fa0; }
}

.rs-input {
	max-width: 340px;
	padding-left: 44px !important;
	background: none;
	font-size: 19px !important;
	color: #1778d3 !important; }

.placeholder-rs {
	position: absolute;
	top: 90px;
	left: 58px;
	font-size: 19px;
	color: #b7b7b7; }

.placeholder {
	color: #b7b7b7; }

.text-placeholder {
	&::-moz-placeholder { // Firefox
		color: #cbced2;
		opacity: 1; }
	&:-ms-input-placeholder { // Internet Explorer 10+
		color: #cbced2; }
	&::-webkit-input-placeholder  { // Safari and Chrome
		color: #cbced2; }
}

.left-icon {
	position: absolute;
	left: 0;
	height: 38px;
	margin: 1px 0 0 1px;
	padding: 10px 12px 0;
	background: #f1f3f6;
	border-right: 1px solid #ccc; }


/* ================================================================== *\



    #Small devices (tablets, 768px and up)



\* ================================================================== */ @media (min-width: 768px) {


.salvar-outro-wrapper {
	padding: 5px 20px 8px;

	> .input-outro {
		padding-right: 74px;
	
		&.unidade {
			width: 69%; }
	}

	> .input-outra-unidade {
		width: 30%; }

	> .tooltip-unidade {
		right: 60px; }
}

.radio-button-block {
	padding: 16px 16px 16px 48px;

	> .radio {
		left: 15px; }

	> .pagamento {
		width: 70%;
		padding: 3px 0 0 0; }

	> .preco {
		width: 30%;
		text-align: right; }
}


/* ================================================================== *\



    #Medium devices (desktops, 992px and up)



\* ================================================================== */ } @media (min-width: 992px) {





/* ================================================================== *\



		#Large devices (large desktops, 1200px and up)



\* ================================================================== */ } @media (min-width: 1200px) {





/* ================================================================== */ } /* END devices */
