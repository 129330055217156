@import "../../../../styles/global_variables.scss";

.vendedor-block {
	p {
		width: 100%;
		padding-bottom: 0px;
		margin-bottom: 0px; } }

.title {
	position: relative;
	float: left;
	width: 100%;
	padding: 0 0 12px 0;
	// font-weight: 500
	color: #000;

	> .code {
		position: relative;
		float: left;
		width: 33%;

		> span {
			color: #8b9097; }

		> p {
			color: #333;
			font-size: 15px; } }

	> .name {
		position: relative;
		float: left;
		width: 66%;
		color: #000;
		font-size: 15px;

		> div {
			color: #333;
			font-size: 15px;
			font-weight: bold; }

		> span {
			color: #8b9097;
			font-size: 14px; } }

	> .status {
		position: relative;
		float: left;
		width: 100%;
		color: #0181B6;

		> span {
			color: #8b9097; } } }

.desassocia-button {
	border-radius: 30px;
	background-color: #d7dade !important;
	opacity: 1;
	padding: 10px !important;
	font-size: 18px;
	position: absolute;
	float: left;
	left: 475px;
	margin-top: 25px;

	> i {
		color: white; }

	&:hover {
		opacity: 1; } }


.modal-content {
	position: relative;
	width: 100%;
	height: 100vh;
	padding: 0;
	overflow: auto; }

.configurado {
	color: #5ad472 !important; }

.no_configurado {
	color: #e43f3f !important; }

.pre_configurado {
	color: #e2c81d !important; }

.hrCustom {
	margin: 0px;
	border: 0;
	border: 1px dashed #eaeaea; }

.id-sienge {
	> p {
		color: #01b0cd; }

	> span {
		color: #a0a6af; }

	> label {
		max-width: 100%;
		margin-bottom: 0px;
		color: #333333;
		font-weight: 400;
		font-family: "Roboto", sans-serif; } }

.close-button {
	position: absolute;
	top: 48px;
	right: 20px;
	font-size: 30px !important;
	width: 40px;
	height: 40px; }

.contentInfoSienge {
	background-color: #f7f9fa;
	padding: 25px 23px 25px;
	float: left;
	width: 100%; }

.content {
	position: relative;
	float: left;
	width: 100%;
	padding: 5px 23px 60px;

	.input {
		padding-left: 41px;
		width: 100%;
		background: none;
		color: #111;
		z-index: 2; }

	> i {
		position: absolute;
		top: 35px;
		left: 16px;
		font-size: 16px;
		color: #acacac;
		z-index: 1; }

	.search-icon {
		position: relative;
		float: left;
		top: 29px;
		left: 15px; }

	&:last-of-type {
		> .inputs {
			position: relative;
			display: block; }

		> button.fornecedor-lista {
			text-align: left;
			padding: 5px 0 5px 0;
			border: none;
			background: white;
			width: 100%;
			&:hover {
				text-decoration: underline; } }

		button:last-of-type {
			top: 10px; } } }

.radio-button-block {
	position: relative;
	display: block;
	width: 100%;
	max-width: 700px;
	margin: 8px 0 8px 0;
	padding: 12px 16px 15px 38px !important;
	background: none;
	border: 1px solid #fff; // d8dde4
	outline: none;
	text-align: left;
	@include border-radius(4px);
	@include box-shadow(0px 0px 0px 1px rgba(216, 221, 228, 0.6));
	@include transition-two(border 0.1s linear, box-shadow 0.1s linear);

	> .radio {
		position: absolute;
		top: 14px;
		left: 9px;
		width: 21px;
		height: 21px;
		border: 1px solid #e3e3e3;
		@include border-radius(30px);

		&:after {
			content: "";
			position: absolute;
			top: 50%;
			left: 50%;
			width: 8px;
			height: 8px;
			margin: -4px 0 0 -4px;
			background-color: $azulradiodot;
			border-color: $azulradiodot; // 448ec3
			// background-color: #1778d3
			// border-color: #1778d3; // 448ec3
			opacity: 0;
			@include border-radius(8px);
			@include transition-one(opacity 0.1s linear); } }

	> .nome {
		position: relative;
		float: left;
		width: 100%;
		// top: 8px
		left: 5px;
		margin-bottom: 2px;
		color: #000;
		font-size: 16px; }

	> .email {
		position: relative;
		// top: 5px
		left: 5px;
		float: left;
		width: 100%;
		text-align: left;
		color: #a5a6ab; }

	> .nao_atende_tag {
		position: relative;
		float: left;
		margin-bottom: 2px;
		padding: 2px 5px 1px;
		color: #fff;
		background: #ff2951;
		border-radius: 3px;
		font-size: 12px;
		text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
		// color: #000;
		// font-weight: bold;
 }		// background: #ffeb0e;

	&:hover {
		@include box-shadow(0px 0px 0px 1px rgba(148, 156, 168, 1)); }

	&.selected {
		//border: 1px solid #1778d3;
		border: 1px solid $azulpadrao;
		// @include box-shadow(0px 0px 0px 1px rgba(68, 142, 195, 1));
		@include box-shadow(0px 0px 0px 1px $azulboxshadow);

		> .radio:after {
			opacity: 1; } }

	> h3 {
		margin-bottom: 21px;
		padding-right: 30px; }

	> .label {
		color: #aaadb7; }

	> em {
		color: #8e919c; }

	> hr {
		margin-top: 34px;
		margin-bottom: 34px; } }

.cadastrado-label {
	display: inline-block;
	margin: 0 0 21px 0; }

.form-novo-vendedor-wrapper {
	position: relative;
	display: block;
	width: 100%;
	max-width: 700px;
	margin: 8px 0 8px 0;
	padding: 12px 16px 28px 38px;
	background: none;
	border: 1px solid #1778d3;
	outline: none;
	text-align: left;
	@include border-radius(4px);
	@include box-shadow(0px 0px 0px 1px rgba(68, 142, 195, 1));
	@include transition-two(border 0.1s linear, box-shadow 0.1s linear);

	> .radio {
		position: absolute;
		top: 14px;
		left: 9px;
		width: 21px;
		height: 21px;
		border: 1px solid #e3e3e3;
		@include border-radius(30px);

		&:after {
			content: "";
			position: absolute;
			top: 50%;
			left: 50%;
			width: 8px;
			height: 8px;
			margin: -4px 0 0 -4px;
			background-color: #1778d3;
			border-color: #1778d3; // 448ec3
			opacity: 1;
			@include border-radius(8px);
			@include transition-one(opacity 0.1s linear); } }

	> .nome {
		position: relative;
		// float: left
		width: 100%;
		margin-bottom: 2px;
		color: #000;
		font-size: 16px; }

	> label {
		display: block;
		margin-top: 15px; }

	> .input-nome {
		max-width: 600px; }

	> .input-ddd {
		display: inline-block;
		max-width: 55px;
		margin-right: 14px; }

	> .input-telefone {
		display: inline-block;
		max-width: 260px; }

	> .input-email {
		max-width: 600px;
		margin-bottom: 26px; }

	> .ddd {
		display: inline-block;
		max-width: 55px;
		margin-right: 14px; }

	> .telefone {
		display: inline-block;
		max-width: 260px; } }

.form-novo-fornecedor-wrapper {
	position: relative;
	display: block;
	width: 100%;
	max-width: 700px;
	margin: 8px 0 10px 0;
	text-align: left;

	> label {
		display: block;
		margin-top: 15px; }

	> input {
		max-width: 600px; }

	> .ddd {
		display: inline-block;
		max-width: 55px;
		margin-right: 14px; }

	> .telefone {
		display: inline-block;
		max-width: 260px; } }

.btn-adicionar {
	margin-top: 36px; }

.btn-adicionar-salvando {
	margin-top: 36px;
	background-color: #a7b1b5 !important;
	border-color: #a7b1b5 !important;

	&:hover {
		background-color: #a7b1b5 !important;
		border-color: #a7b1b5 !important; } }

.obrigatorio {
	color: #e43f3f; }

.somente-triagem {
	color: #5ad472; }

.checkbox-wrapper {
	position: relative;
	margin: 40px 0 0 0; }

.checkbox-button {
	position: relative;
	width: 100%;
	padding: 8px 0 6px 28px;
	background: none;
	border: none;
	outline: none;
	text-align: left;
	color: #5ad472;

	> .checkbox-icon {
		position: absolute;
		top: 9px;
		left: 0;
		width: 20px;
		height: 20px;
		margin: -2px 10px 0 0;
		padding: 2px 0 0 2px;
		vertical-align: middle;
		text-align: center;
		border: 1px solid #ccc;
		border-radius: 3px;
		font-size: 14px;
		cursor: pointer; }

	&.checked {
		> .checkbox-icon {
			padding-top: 0;
			color: #fff;
			background: #337ab7;
			border: 1px solid #2f73ac;

			> i {
				position: absolute;
				top: 3px;
				left: 2px; } } } }

.reenviar-wrapper {
	position: relative;
	margin: 28px 0 4px 0;
	color: #5ad472; }

.vendedor-wrapper {
	position: relative;
	float: left;
	width: 100%;
	padding: 6px 20px 12px 25px;
	text-align: left;

	> i {
		position: absolute;
		top: 10px;
		left: 0;
		color: #c7c9ce; }

	> .nome {
		color: #111; }

	> .email {
		color: #2e8bbd; }

	> .telefone {
		color: #8e919c; } }

.error-wrapper {
	position: relative;
	text-align: center;
	padding: 30px 15px 41px 15px;

	> i {
		font-size: 90px;
		color: #ccc; }

	> h3 {
		margin-bottom: 20px;
		line-height: 1.3; }

	> p {
		color: #777; } }

.novo-telefone-button {
	padding: 13px 0 5px 0 !important;
	outline: none !important; }

/* ================================================================== *\ */

/* Small devices (tablets, 768px and up) */

/* ================================================================== */
@media (min-width: 768px) {
	.contentInfoSienge {
		padding: 25px 46px 25px; }

	.content {
		padding: 15px 46px 15px; }

	.contentInfoSienge {
		padding: 20px 46px 20px; }

	.radio-button-block {
		padding: 12px 16px 13px 46px;

		> .radio {
			left: 13px; } }

	.form-novo-vendedor-wrapper {
		padding: 12px 16px 28px 46px;

		> .radio {
			left: 13px; } }

	/* ================================================================== */
 /* END devices */ }
